import React from "react"
import { Link } from "gatsby"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="404 Error"
          description="The page you were looking for couldn’t be found."
        />

        <div className="uk-section uk-section-default">
          <div className="uk-container uk-container-small uk-text-center">
            <div
              className="uk-flex uk-flex-middle uk-flex-center"
              uk-height-viewport="expand: true"
            >
              <div className="uk-width-4-5@s uk-width-2-3@l">
                <h2 className="uk-text-bold uk-h3">404</h2>
                <p>
                  404 Error: page not found. Unfortunately, the page you were
                  looking for couldn’t be found on our site. Use the menu to
                  explore Kaden’s range of air conditioners, evaporative coolers
                  and gas ducted heaters.
                </p>
                <p>
                  Think there should be something here? Visit the{" "}
                  <Link to="faqs">support</Link> page and send us an email.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index
